<template>
  <div>
    <el-card class="box-card">
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入收款单号" v-model="querInfo.p_serial_number" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">

        </el-col>
      </el-row>
      <el-table :data="paymentcensusList" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="收款单号" prop="p_serial_number" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="details(scope.row.p_id)"
                 style="color: #66b1ff;text-decoration:underline;cursor:pointer;">
              {{scope.row.p_serial_number}}</div>
          </template>
        </el-table-column>
        <el-table-column label="业务单号" prop="re_code" show-overflow-tooltip></el-table-column>
        <el-table-column label="业务类型" prop="p_type_name" ></el-table-column>
        <el-table-column label="支付方式" >
          <template slot-scope="scope">
            <div style="padding: 2px 5px;background: #8c939d;display: inline-block;
            color:#ffffff;border-radius: 4px">
              {{scope.row.p_payment_method}}</div>
          </template>
        </el-table-column>
        <el-table-column label="金额">
          <template slot-scope="scope">
            <div style="color:red;font-weight: bold;">
              {{scope.row.p_payment_price}}</div>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="p_remark" ></el-table-column>
        <el-table-column label="录单日期" prop="p_payment_date" ></el-table-column>
        <!--        <el-table-column label="操作" width="250px" fixed="right">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-tooltip effect="dark" content="详情" placement="top" :enterable="true">-->
        <!--              <el-button type="primary" size="mini" icon="el-icon-tickets"-->
        <!--                         @click="paydetails(scope.row.p_id)"></el-button>-->
        <!--            </el-tooltip>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <el-dialog
        title="详情" :visible.sync="DialogVisible" width="70%" @close="editCateDialogClose">
      <el-row :gutter="30" style="margin-bottom: 30px;">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <span class="title">收款单号：</span>
          <span class="text">{{saledeailsList.p_serial_number}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">收付款金额：</span>
          <span class="text redbold" >{{saledeailsList.p_payment_price}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">收付款日期：</span>
          <span class="text">{{saledeailsList.p_payment_date}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">支付方式：</span>
          <span class="text">{{saledeailsList.p_payment_method}}</span>
        </el-col>
        <el-col v-if="saledeailsList.p_account_name" :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">账户名称：</span>
          <span class="text">{{saledeailsList.p_account_name}}</span>
        </el-col>
        <el-col v-if="saledeailsList.p_account_bank" :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">开户银行：</span>
          <span class="text">{{saledeailsList.p_account_bank}}</span>
        </el-col>
        <el-col v-if="saledeailsList.p_bank_account" :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">银行账号：</span>
          <span class="text">{{saledeailsList.p_bank_account}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">订单类型：</span>
          <span class="text">{{saledeailsList.p_type}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">备注：</span>
          <span class="text">{{saledeailsList.p_remark}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">创建时间：</span>
          <span class="text">{{saledeailsList.add_date}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}" >
          <span class="title">创建人：</span>
          <span class="text">{{saledeailsList.add_uid}}</span>
        </el-col>
        <el-col v-if="saledeailsList.p_payment_img" :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:24,offset:0}" :lg="{span:24,offset:0}" :xl="{span:24,offset:0}" >
          <span class="title">支付凭证：</span>
          <!--            <span class="text">{{// saledeailsList.add_uid}}</span>-->
          <img :src="saledeailsList.p_payment_img" alt="">
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>
<script>
export default {
  data() {
    return {
      querInfo: {
        page: 1,
        limit: 10,
      },
      total:0,
      paymentcensusList:[],
      DialogVisible:false,
      saledeailsList:{},
    }
  },
  created() {
      this.getpaymentcensusList()
  },
  methods: {
    async getpaymentcensusList() {
      this.loading=true
      const {data: res} = await this.$http.get('collectioncensus/getCollectionList',
          {params: this.querInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.paymentcensusList = res.data.data
      this.total = res.data.total
      this.loading=false
    },
    //获取详情
    async getsaledeailsList(id) {
      const {data: res} = await this.$http.get(`paymentcensus/find/`+id)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.saledeailsList = res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getpaymentcensusList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getpaymentcensusList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getpaymentcensusList()
    },
    editCateDialogClose(){

    },
    details(id){
      this.DialogVisible=true
      this.getsaledeailsList(id)
    },
  }
}
</script>
<style>

</style>
